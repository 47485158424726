<template>
  <v-card elevation="5" class="mx-auto pa-8">
    <v-card-title class="justify-center text-h4">{{ $t('messages.unsupportedBrowser.header') }}</v-card-title>
    <v-card-subtitle class="justify-center text-h5">{{ $t('messages.unsupportedBrowser.message') }}</v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'UnsupportedBrowser'
}
</script>
